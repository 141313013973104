<template>
  <div id="issueOrder">
    <div class="tabsContainer">
      <el-tabs v-model="form.status" @tab-click="handleClick">
        <el-tab-pane :label="pendingTabText" name="待处理"></el-tab-pane>
        <el-tab-pane :label="beingProcessedTabText" name="处理中"></el-tab-pane>
        <el-tab-pane :label="historicalIssueTabText" name="历史问题"></el-tab-pane>
      </el-tabs>
    </div>
    <!-- 列表 -->
    <div class="list">
      <div class="searchBox">
        <div class="myInput">
          <i class="el-icon-search"></i>
          <input type="text" v-model="form.searchText" @keydown.enter="getData()" :placeholder="$fanyi('请输入订单号搜索')" />
        </div>
        <button class="searchBtn" @click="searchBtn">
          {{ $fanyi('搜索') }}
        </button>
      </div>
      <!-- 表格 -->
      <div class="dataBody" v-loading="loading">
        <el-table :data="tableData" border style="width: 100%" :header-cell-style="{
          'text-align': 'center',
          background: '#F6F6F6',
          color: '#222222',
          height: '60px',
          'font-size': '14px',
          'font-weight': '500',
        }" :cell-style="{ 'text-align': 'center', height: '60px', 'font-size': '14px' }">
          <el-table-column type="index" width="60" label="No">
          </el-table-column>

          <el-table-column prop="order_sn" :label="$fanyi('订单号')" width="250">
            <template slot-scope="scope">
              <button class="order_sn" @click="
                $fun.toPage(
                  '/issueOrderDetail?order_sn=' +
                  scope.row.order_sn +
                  '&status=' +
                  $fun.toCode(form.status)
                )
                ">
                {{ scope.row.order_sn }}
              </button>
            </template>
          </el-table-column>
          <el-table-column prop="name" :label="$fanyi('联系负责人')" width="183" @click="tocaht">
            <template slot-scope="scope">
              <img class="lianxiren" src="../../../../assets/user-img/contact.svg" alt="" @click="tocaht" />
            </template>
          </el-table-column>
          <el-table-column prop="problem_goods_created_at" width="222" :label="$fanyi('问题提出日期')">
          </el-table-column>
          <el-table-column prop="count" :label="$fanyi('有问题的商品数')">
          </el-table-column>
          <el-table-column prop="sum" :label="$fanyi('问题商品数量')">
          </el-table-column>
        </el-table>
        <div class="allpag">
          <span></span>
          <el-pagination @current-change="handleCurrentChange" :current-page="form.currentPage" :page-size="10"
            :page-sizes="[10, 20, 30, 50]" layout=" prev, pager, next" :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        status: '待处理',
        page: 1,
        pageSize: 10,
        searchText: '',
      },
      tableData: [],
      loading: false,
      total: 0,
      pendingTabText: this.$fanyi('等待处理') + '(0)',
      beingProcessedTabText: this.$fanyi('处理中') + '(0)',
      historicalIssueTabText: this.$fanyi('所有问题产品详情') + '(0)',
    }
  },

  created() {
    this.getData()
    this.getTitleNumber()
  },
  methods: {
    searchBtn() {
      this.form.page = 1
      this.getData(this.form)
    },
    // 打开聊天窗口
    tocaht() {
      let element = document.getElementById("zsiq_float");
      // 创建事件
      var event = document.createEvent("MouseEvents");
      // 定义事件 参数： type, bubbles, cancelable
      event.initEvent("click", true, true);
      // 触发对象可以是任何元素或其他事件目标
      element.dispatchEvent(event);

    },
    handleClick() {
      this.form.page = 1
      this.getData(this.form)
    },
    // 获取种类数量数据
    getTitleNumber() {
      this.$api.problemGoodsOrderNum().then((res) => {
        if (res.code != 0) return
        if (res.data.length == 0) return
        // 循环匹配数量
        res.data.forEach((item) => {
          switch (item.table) {
            case '待处理':
              this.pendingTabText = this.$fanyi('等待处理') + ` (${item.num})`
              break
            case '处理中':
              this.beingProcessedTabText =
                this.$fanyi('处理中') + ` (${item.num})`
              break
            case '已处理':
              this.historicalIssueTabText =
                this.$fanyi('所有问题产品详情') + ` (${item.num})`
              break
          }
        })
      })
    },
    // 获取数据
    getData() {
      this.loading = true
      let datas = {
        status: this.form.status,
        pageSize: this.form.pageSize,
        page: this.form.page,
        keywords: this.form.searchText,
      }
      this.$api.problemGoodsOrderList(datas).then((res) => {
        this.loading = false
        if (res.code != 0) return
        this.tableData = res.data.data
        this.total = res.data.total
      })
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.form.pageSize = 1
      this.getData(this.form)
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.getData(this.form)
    },
  },
}
</script>
<style lang="scss" scoped>
#issueOrder {
  @import '../../../../css/mixin';
  width: 1180px;
  background: #FFFFFF;
  border-radius: 6px;
  border: 1px solid #E2E2E2;
  overflow: hidden;


  .tabsContainer {
    height: 55px;
    border-bottom: 1px solid #E2E2E2;
    border-radius: 0 !important;
    padding-left: 20px;
    display: flex;
    padding-top: 16px;

    /deep/ .el-tabs {
      height: 55px;

      .el-tabs__item {
        padding: 0 15px;

        color: #222222;
        line-height: 24px;
      }
    }

    /deep/.el-tabs__nav {
      bottom: 2px;
    }

    /deep/ .el-tabs__nav-wrap::after {
      display: none;
    }
  }

  .list {
    border-radius: 0;
    padding: 20px 30px 30px 30px;

    .searchBox {
      display: flex;
      margin-bottom: 20px;

      .myInput {
        position: relative;
        width: 320px;
        height: 40px;
        background: #ffffff;
        border-radius: 4px;

        display: flex;
        align-items: center;
        overflow: hidden;
        margin-right: 20px;

        >i {
          width: 20px;
          height: 24px;
          margin: 9px 11px 9px 12px;
          display: flex;
          justify-content: center;
          color: #CDCDCD;
          align-items: center;
          position: absolute;
        }

        >input {
          width: 320px;
          height: 40px;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #E2E2E2;
          font-size: 14px;
          padding-left: 40px;
          padding-right: 10px;
          transition: 0.3s;

          &:focus {
            border-color: #1e2997;
          }
        }
      }

      /deep/ .el-date-editor {
        // width: 230px;
        height: 40px;
        margin-right: 20px;
        border-radius: 4px;

        .el-range-input {
          font-size: 14px;
        }
      }

      .searchBtn {
        width: 80px;
        height: 40px;
        background: #ff730b;
        border-radius: 4px;

        font-size: 16px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #ffffff;
        line-height: 40px;
        text-align: center;
      }
    }

    .dataBody {

      /deep/ .el-table__header {
        th {
          border-right: none;


          div {
            align-items: center;
            justify-content: center;
            display: flex;
            line-height: 16px;
            text-align: center;
            word-break: keep-all;

          }


        }
      }

      /deep/ .el-table__cell>.cell {
        font-size: 14px;
      }

      .order_sn {
        font-size: 14px;
        color: #ff730b;
        line-height: 20px;
        background-color: transparent;

        &:hover {
          text-decoration: underline;
          color: #ff730b;
        }
      }

      .lianxiren {
        width: 32px;
        height: 32px;
        cursor: pointer;
      }
    }

    .allpag {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 25px 0;

      .el-pagination {
        display: flex;
        align-items: center;
      }

      >a {
        display: inline-block;
        border: 1px solid #dcdfe6;
        height: 28px;
        // background-color: white;
        text-align: center;
        padding: 0 10px;
        font-size: 13px;
        line-height: 28px;
        border-radius: 0;
      }

      /deep/.btn-prev {
        width: 40px;
        height: 40px;
        background: #f5f5f5;
        border-radius: 20px;
        padding: 0;
        margin-right: 30px;

        .el-icon {
          font-size: 15px;
        }
      }

      /deep/.btn-next {
        width: 40px;
        height: 40px;
        background: #f5f5f5;
        border-radius: 20px;
        padding: 0;
        margin-left: 30px;

        .el-icon {
          font-size: 15px;
        }
      }

      /* 分页被选中的页码样式 */
      /deep/ .el-pager li {
        margin: 0 10px;
        // border-radius: 0;
        min-width: 20px !important;
        font-size: 14px;
        height: 30px;
        color: #999999;
        // box-shadow: 0 0 6px 0 rgba(61, 61, 61, 0.1);

        &:hover {
          color: #ff730b;
        }

        &.active {
          color: #ff730b;
          border-bottom: 2px solid #ff730b;
          width: 20px !important;
        }
      }
    }

  }


}
</style>
