var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"issueOrder"}},[_c('div',{staticClass:"tabsContainer"},[_c('el-tabs',{on:{"tab-click":_vm.handleClick},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},[_c('el-tab-pane',{attrs:{"label":_vm.pendingTabText,"name":"待处理"}}),_c('el-tab-pane',{attrs:{"label":_vm.beingProcessedTabText,"name":"处理中"}}),_c('el-tab-pane',{attrs:{"label":_vm.historicalIssueTabText,"name":"历史问题"}})],1)],1),_c('div',{staticClass:"list"},[_c('div',{staticClass:"searchBox"},[_c('div',{staticClass:"myInput"},[_c('i',{staticClass:"el-icon-search"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.searchText),expression:"form.searchText"}],attrs:{"type":"text","placeholder":_vm.$fanyi('请输入订单号搜索')},domProps:{"value":(_vm.form.searchText)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getData()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "searchText", $event.target.value)}}})]),_c('button',{staticClass:"searchBtn",on:{"click":_vm.searchBtn}},[_vm._v(" "+_vm._s(_vm.$fanyi('搜索'))+" ")])]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"dataBody"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","header-cell-style":{
        'text-align': 'center',
        background: '#F6F6F6',
        color: '#222222',
        height: '60px',
        'font-size': '14px',
        'font-weight': '500',
      },"cell-style":{ 'text-align': 'center', height: '60px', 'font-size': '14px' }}},[_c('el-table-column',{attrs:{"type":"index","width":"60","label":"No"}}),_c('el-table-column',{attrs:{"prop":"order_sn","label":_vm.$fanyi('订单号'),"width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('button',{staticClass:"order_sn",on:{"click":function($event){_vm.$fun.toPage(
                '/issueOrderDetail?order_sn=' +
                scope.row.order_sn +
                '&status=' +
                _vm.$fun.toCode(_vm.form.status)
              )}}},[_vm._v(" "+_vm._s(scope.row.order_sn)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":_vm.$fanyi('联系负责人'),"width":"183"},on:{"click":_vm.tocaht},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('img',{staticClass:"lianxiren",attrs:{"src":require("../../../../assets/user-img/contact.svg"),"alt":""},on:{"click":_vm.tocaht}})]}}])}),_c('el-table-column',{attrs:{"prop":"problem_goods_created_at","width":"222","label":_vm.$fanyi('问题提出日期')}}),_c('el-table-column',{attrs:{"prop":"count","label":_vm.$fanyi('有问题的商品数')}}),_c('el-table-column',{attrs:{"prop":"sum","label":_vm.$fanyi('问题商品数量')}})],1),_c('div',{staticClass:"allpag"},[_c('span'),_c('el-pagination',{attrs:{"current-page":_vm.form.currentPage,"page-size":10,"page-sizes":[10, 20, 30, 50],"layout":" prev, pager, next","total":_vm.total},on:{"current-change":_vm.handleCurrentChange}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }